/*document.addEventListener('touchstart', onTouchStart, {passive: true});*/
//var sb_instagram_js_options = {"sb_instagram_at":"","font_method":"svg"};
var scrollM = 0;
$(document).ready(function() {
    isSubmit = $( "body" ).has( "#gform_confirmation_message_1" ).length;
    if(isSubmit == 1){
        dataLayer.push({'event': 'form_sent'});
        //console.log('send');
    }
    //if($( window ).width() > 868){
        var position = $('#header').position().top;
        $(document).on('scroll', function() {
            if($(this).scrollTop()>=position){
                $('#header').addClass('fixed');
            }else{
                $('#header').removeClass('fixed');
            }
            if($(this).scrollTop() > 600){
                $('.top').addClass('active');
            }else{
                $('.top').removeClass('active');
            }
        })
    //}

    var owl  = $('.owl-hp');
    var owl2 = $('.owl-subpage');

    owl.on('refresh.owl.carousel', function(event) {
        $('#slider').removeClass('loading');
    })

    owl.owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        animateOut: 'fadeOut',
        navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
        items: 1,
        autoplay:true,
        autoplayTimeout:5000
    });
    owl2.owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        dots: true,
        animateOut: 'fadeOut',
        navText: ['<i class="icon icon-arrow3"><i>','<i class="icon icon-arrow3"><i>'],
        items: 1,
        autoplay:true,
        autoplayTimeout:5000
    });




    $('input').iCheck({
      checkboxClass: 'icheckbox_minimal-red',
      radioClass: 'iradio_minimal-red',
      increaseArea: '20%' // optional
    });
    $( ".accordJs .col" ).click(function() {
        i = false;
        i = $(this).closest('.accordJs').hasClass('company-value');

        if($(this).hasClass('active')){
            $(this).removeClass('active');
            if(i){
                $(this).find('i').addClass('icon-circle-arrow').removeClass('icon-close-circle');
            }
        }else{
            $(this).closest('.accordJs').find('.col').removeClass('active');
            $(this).addClass('active');
            if(i){
                $(this).find('i').removeClass('icon-circle-arrow').addClass('icon-close-circle');
            }
        }
    });
    $( ".article .js-hidden" ).click(function() {
        obj = $(this);
        /*
        if(!obj.css('display','block')){
            $('html, body').animate({
              scrollTop: $(this).closest('.article').offset().top
            }, 500);
        }
        */
        $(this).closest('.article').find('.row.hidden').slideToggle( 'slow', function() {
             val = $(this).css('display');

             if(val == 'block'){
                 obj.addClass('hidden');

             }else{
                 //obj.removeClass('hidden');
                 obj.closest('.article').find('.js-hidden.hidden').removeClass('hidden');
                 $('html, body').animate({
                   scrollTop: $(this).closest('.article').offset().top
                 }, 500);
             }
        });


        //console.log(obj.html());
        //obj.slideToggle( "slow" );
        //obj.slideDown( "slow");
        /*
        if(obj.hasClass('active')){
            obj.removeClass('active').toggle();
            $('html, body').animate({
              scrollTop: $(this).closest('.article').offset().top
            }, 500);
        }else{
            obj.addClass('active').toggle();
        }
        */
        /*
        $(this).css('display','none');
        obj.find('.row.hidden').removeClass('hidden');
        */
    });
    $('a.toTop,a.top').click(function(){
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    $( "#form input,#form textarea" ).focusin(function() {
        $(this).closest('li').addClass('focus');
    });
    $( "#form input,#form textarea" ).focusout(function() {
        val = $(this).val();
        if(val == ''){
            $(this).closest('li').removeClass('focus');
        }
    });
    $( "#header .mobileNav" ).click(function() {
        obj = $(this).closest('#header').find('.mobileNavs');
        if(obj.hasClass('active')){
            if(scrollM != 0){
                $('html, body').animate({
                       scrollTop: scrollM
                }, 100);
            }
            $('body').removeClass('locked');
            $(this).find('i').addClass('icon-menu').removeClass('icon-close');
            obj.removeClass('active');
        }else{
            scrollM = $(document).scrollTop();
            $('body').addClass('locked');
            $(this).find('i').removeClass('icon-menu').addClass('icon-close');
            obj.addClass('active');
        }
    });

    $( window ).resize(function() {
        obj = $( "#header .mobileNav" ).closest('#header').find('.mobileNavs');

        $('body').removeClass('locked');
        $( "#header .mobileNav" ).find('i').addClass('icon-menu').removeClass('icon-close');
        obj.removeClass('active');
    });

    $(".blog-article.blog-type").click(function(){
         window.location=$(this).find("a").attr("href");
         return false;
    });

    $('.gallery a,.blocks-gallery-item a,.wp-block-image a').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
});
